<template>
	<div>
		<canvas :id="chartname" width="400" height="400"></canvas>
	</div>
</template>

<script>
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
	name: 'fac-polar-area',
	props: ['chartname', 'chartdata', 'options'],
	data() {
		return {
			drawnGraph: []
		}
	},
	mounted() {
		this.drawGraph()
	},
	beforeDestroy() {
		this.drawnGraph.destroy()
	},
	methods: {
		drawGraph() {
			Chart.register(ChartDataLabels)
			let hasQuadrants = false
			if (this.options && this.options.plugins && this.options.plugins.quadrants) {
				hasQuadrants = true
			}
			setTimeout(() => {
				this.drawnGraph = new Chart(this.chartname, {
					type: 'polarArea',
					data: this.chartdata,
					options: this.options
				})
			}, 150)
		}
	}
}
</script>
