<template>
	<v-container no-gutters fluid class="pa-0 ma-0" :class="showCharts ? 'white' : ''" stylr="z-index: 99999;">
		<!-- AREA DO RELATORIO -->
		<div class="pa-4" v-if="invalidReport">
			<v-alert border="left" colored-border type="warning" elevation="2" :value="true">
				Desculpe, o relatório não foi carregado pois está vencido ou inválido ou você não tem permissões para acessá-lo.
			</v-alert>

			<v-btn color="primary" class="mt-4" @click="$router.go(-1)">Voltar</v-btn>
		</div>

		<div class="pa-0 highIndex" v-if="showCharts">
			<v-app-bar dense flat color="transparent" class="d-print-none">
				<v-btn icon large color="primary" dark @click="showCharts = false, $router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
				<v-toolbar-title class="primary--text">{{ 'Relatório' }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn v-if="$vuetify.breakpoint.mdAndUp" color="primary" dark @click="callPrint()" class="ml-0 pl-0 mt-3"><v-icon class="ml-2 mr-3">mdi-upload</v-icon> Exportar</v-btn>
			</v-app-bar>

			<v-row no-gutters>
				<v-col sm="12" class="d-print-none" :class="$vuetify.breakpoint.mdAndUp ? 'ml-12' : ''">
					<p class="mb-0 primary--text" :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 pl-12 text-h3' : 'pt-4 pl-4 text-h4 font-weight-bold'">{{ 'Facilitador do Autoconhecimento' }}</p>
					<p v-if="!differentClient" class="mb-0 grey--text text--darken-1" :class="$vuetify.breakpoint.mdAndUp ? 'pl-12 text-h4' : 'pl-4 text-h5 font-weight-bold'">
						{{ userData && userData.completeName ? userData.completeName : userData && userData.email ? userData.email : '' }}
					</p>
					<p v-if="differentClient" class="mb-0 grey--text text--darken-1" :class="$vuetify.breakpoint.mdAndUp ? 'pl-12 text-h4' : 'pl-4 text-h5 font-weight-bold'">
						{{ clientData && clientData.completeName ? clientData.completeName : clientData && clientData.email ? clientData.email : '' }}
					</p>
					<p class="mb-0 grey--text text--lighten-1" :class="$vuetify.breakpoint.mdAndUp ? 'pl-12 text-h6' : 'pl-4 text-subtitle-1'" v-if="reportData && reportData.id">{{ reportData && reportData.id ? `REL-${reportData.id}` : '' }}</p>
					<p class="mb-0 grey--text text--lighten-1" :class="$vuetify.breakpoint.mdAndUp ? 'pl-12 text-h6' : 'pl-4 text-subtitle-1'" v-if="reportData && reportData.id">Início: {{ formatDate(reportData.dateStarted) }} - Término {{ formatDate(reportData.dateFinished) }}</p>
				</v-col>
				<v-col sm="12" class="d-none d-print-block">
					<p class="mb-0 primary--text" :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 text-h3' : 'pt-4 text-h4 font-weight-bold'">{{ 'Facilitador do Autoconhecimento' }}</p>
					<p v-if="!differentClient" class="mb-0 grey--text text--darken-1" :class="$vuetify.breakpoint.mdAndUp ? 'text-h4' : 'pt-4 text-h5 font-weight-bold'">
						{{ userData && userData.completeName ? userData.completeName : userData && userData.email ? userData.email : '' }}
					</p>
					<p v-if="differentClient" class="mb-0 grey--text text--darken-1" :class="$vuetify.breakpoint.mdAndUp ? 'text-h4' : 'pt-4 text-h5 font-weight-bold'">
						{{ clientData && clientData.completeName ? clientData.completeName : clientData && clientData.email ? clientData.email : '' }}
					</p>
					<p class="mb-0 grey--text text--lighten-1" :class="$vuetify.breakpoint.mdAndUp ? 'text-subtitle-2' : 'text-subtitle-2'" v-if="reportData && reportData.id">{{ reportData && reportData.id ? `REL-${reportData.id}` : '' }}</p>
					<p class="mb-0 grey--text text--lighten-1" :class="$vuetify.breakpoint.mdAndUp ? 'text-subtitle-2' : 'text-subtitle-2'" v-if="reportData && reportData.id">Início: {{ formatDate(reportData.dateStarted) }} - Término {{ formatDate(reportData.dateFinished) }}</p>
				</v-col>
			</v-row>
			
			<v-divider class="mt-4"></v-divider>
			
			<v-row no-gutters>
				<v-col sm="0" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
				<v-col sm="12" md="8">
					<p class="mb-0 primary--text" :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 text-h5' : 'pt-4 text-h6 font-weight-bold'">{{ 'Prezado(a),' }}</p>

					<p class="pt-4 grey--text text--darken-2">
						O aplicativo FAC (Facilitador do Autoconhecimento) foi desenhado, projetado e testado para que a pessoa responda de forma lúdica sentindo segurança nas informações, fazendo com que a assertividade de sua avaliação seja maior que 98%.
					</p>
					<p class="grey--text text--darken-2">
						Este instrumento particular tem a finalidade de exibir o resultado descritivo e graficamente do questionário respondido por você, onde foi feita uma análise de seus valores pessoais através das classificações que você realizou, por isso é importante ter respondido verdadeiramente sem seu autojulgamento.
					</p>
					<p class="grey--text text--darken-2">
						A partir das classificações realizadas será feito um diagnóstico sobre seus valores pessoais e com qual intensidade influenciam no seu comportamento ou desempenho pessoal.
					</p>
					<blockquote class="grey--text text--darken-1 font-italic text-subtitle-2">
						<!-- OLD TEXT 
						Todos os SISTEMAS HUMANOS têm de fazer certas ESCOLHAS;<br />
						Essas escolhas são CONTRADITÓRIAS muitas vezes;<br />
						Nos REVELAMOS quando escolhemos entre ALTERNATIVAS contraditórias;<br />
						Nossas CRENÇAS são as bases dos nossos VALORES, das nossas ATITUDES e determinam nossas AÇÕES.
						-->
						Devido as <span class="text-h6">diversidades</span> que a natureza e o mundo nos <span class="text-h6">oferece</span>, temos que fazer escolhas;<br />
						<span class="text-h6">As vezes</span> acreditamos que a <span class="text-h6">escolha</span> que fizemos <span class="text-h6">não</span> foi <span class="text-h6">adequada</span>;<br />
						São as <span class="text-h6">dúvidas</span> das escolhas que <span class="text-h6">apontam</span> a sua <span class="text-h6">identidade</span> interior;<br />
						As <span class="text-h6">crenças</span> nos <span class="text-h6">limitam</span> e nos <span class="text-h6">impulsionam</span> formando nossa <span class="text-h6">personalidade</span> e determinando nossos <span class="text-h6">valores</span>;<br />
						Nossas <span class="text-h6">atitudes</span> se baseiam em nossos <span class="text-h6">valores</span> que determinam nossas <span class="text-h6">ações</span>.
					</blockquote>

					<div class="mt-12">
						<fac-polar-area :chartname="'polarGroups'" :chartdata="chartDataGroups" :options="chartDataGroupsOptions"></fac-polar-area>
					</div>
				</v-col>
				<v-col sm="12" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
			</v-row>

			<template>
				<v-divider class="mt-4 pagebreak"></v-divider>

				<v-row no-gutters v-for="(data, index) in processedDimensions" :key="index">
					<v-col sm="0" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
					<v-col sm="12" md="8">
						<p v-if="index === 0" class="mb-0 primary--text" :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 text-h4' : 'pt-4 text-h5 font-weight-bold'">{{ 'Avaliação do Resultado' }}</p>
						<p v-if="index === 0" class="caption grey--text">
							<v-btn small color="primary" depressed class="d-print-none" v-if="gameData && gameData.data && gameData.data.videoLink" :href="gameData.data.videoLink" target="_blank">
								<v-icon left>mdi-play</v-icon> Veja o Vídeo de Introdução
							</v-btn>
							<a v-if="gameData && gameData.data && gameData.data.videoLink" class="d-none d-print-block" :href="gameData.data.videoLink" target="_blank">Veja o vídeo de introdução</a>
						</p>
						
						<p class="grey--text text--darken-4 mb-0" :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 text-h5' : 'pt-4 text-h6 font-weight-bold'">{{ data.name }}</p>
						<p class="caption grey--text">
							<a v-if="data.video" :href="data.video" target="_blank">Veja mais detalhes neste vídeo.</a>
						</p>

						<p class="grey--text text--darken-2" v-for="(dimension, idx) in data.dimensions" :key="'dim_'+idx">
							<span class="font-weight-bold">{{ dimension.name }}</span>
							<v-btn x-small color="primary lighten-2" depressed class="ml-3 d-print-none" v-if="dimension.video" :href="dimension.video" target="_blank">
								<v-icon left>mdi-play</v-icon> Veja o Vídeo
							</v-btn>
							<br/>

							{{ dimension.description }}<br/>

							<a v-if="dimension.video" :href="dimension.video" target="_blank" class="d-none d-print-block">Veja mais detalhes neste vídeo.</a>
						</p>
					</v-col>
					<v-col sm="0" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
				</v-row>

				<v-row no-gutters v-if="reportData.accessType === 1" class="d-print-none">
					<v-col sm="0" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
					<v-col sm="12" md="8">
						<template>
							<p class="mb-0 primary--text" :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 text-h6' : 'pt-4 text-h6 font-weight-bold'">{{ 'Tenha acesso ao relatório completo!' }}</p>
							<p class="grey--text">Compre já para ter o resultado completo do seu relatório. Veja as opções abaixo:</p>
						</template>
					</v-col>
					<v-col sm="0" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>

					<v-col sm="12" md="12">
						<fac-products :showFree="false" :fpAlign="'center'" :fpJustify="'center'"></fac-products>
					</v-col>
				</v-row>

				<v-divider class="mt-4 pagebreak"></v-divider>
			</template>

			<v-row no-gutters>
				<v-col sm="0" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
				<v-col sm="12" md="8">
					<!--
					<template v-if="userData && userData.access === 3">
						<p class="mb-0 primary--text" :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 text-h4' : 'pt-4 text-h5 font-weight-bold'">{{ 'Avaliação Gráfica' }}</p>
						<p class="mb-4 font-weight-bold text-subtitle-1">{{ 'Para um relatório mais detalhado, contate o responsável pela administração.' }}</p>
					</template>
					-->
					<template>
						<p class="mb-4 primary--text" :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 text-h4' : 'pt-4 text-h5 font-weight-bold'">{{ 'Avaliação Gráfica' }}</p>
					</template>
					
					<div class="mt-4" v-if="reportData.accessType > 1">
						<fac-bar-chart :chartname="'barDimension'" :chartdata="chartDataBar" :options="chartDataBarOptions"></fac-bar-chart>
					</div>

					<div class="mb-8 text-center">
						<p class="mb-2 text-subtitle-2 grey--text">Legenda:</p>
						<div v-if="$vuetify.breakpoint.mdAndUp">
							<span class="chip-green px-4 py-2 white--text text-subtitle-2">Pontuação Alta</span>
							<span class="ml-2 chip-yellow px-4 py-2 white--text text-subtitle-2">Pontuação Média</span>
							<span class="ml-2 chip-red px-4 py-2 white--text text-subtitle-2">Pontuação Baixa</span>
						</div>
						<div v-else class="d-flex justify-center">
							<p style="width: 150px;" class="text-center chip-green px-4 py-2 white--text text-subtitle-2">Pontuação Alta</p>
							<p class="ml-2 chip-yellow px-4 py-2 white--text text-subtitle-2">Pontuação Média</p>
							<p class="ml-2 chip-red px-4 py-2 white--text text-subtitle-2">Pontuação Baixa</p>
						</div>
					</div>

					<div class="mt-4">
						<fac-polar-area :chartname="'polarDimension'" :chartdata="chartData" :options="chartOptions"></fac-polar-area>
					</div>
				</v-col>
				<v-col sm="0" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
			</v-row>

			<template v-if="reportData.accessType > 2">
				<v-divider class="mt-4 pagebreak"></v-divider>
				<v-row no-gutters v-for="(data, index) in processedDimensions" :key="index+'_descriptive'">
					<v-col sm="0" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
					<v-col sm="12" md="8">
						<p v-if="index === 0" class="mb-0 primary--text" :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 text-h4' : 'pt-4 text-h5 font-weight-bold'">{{ 'Sugestões para Tomada de Ação' }}</p>
						
						<p class="grey--text text--darken-4 mb-0" :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 text-h5' : 'pt-4 text-h6 font-weight-bold'">{{ data.name }}</p>

						<p class="grey--text text--darken-2" v-for="(dimension, idx) in data.dimensions" :key="'dim_'+idx">
							<span class="font-weight-bold">{{ dimension.name }}</span><br />
							{{ dimension.descriptive.description }}<br/>
							<span class="font-weight-medium caption">Característica: <span class="font-weight-light caption">{{ dimension.descriptive.characteristic }}</span></span><br />
							<span class="font-weight-medium caption">Ponto Positivo: <span class="font-weight-light caption">{{ dimension.descriptive.positive }}</span></span><br />
							<span class="font-weight-medium caption" v-if="dimension.descriptive.improvement">Ponto de Melhora: <span class="font-weight-light caption">{{ dimension.descriptive.improvement }}</span></span>
						</p>
					</v-col>
					<v-col sm="0" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
				</v-row>
			</template>

			<v-divider class="mt-4 mb-4 pagebreak"></v-divider>

			<v-row no-gutters>
				<v-col sm="0" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
				<v-col sm="12" md="8">
					<p class="caption grey--text">
						<v-btn small color="primary" depressed class="d-print-none" v-if="gameData && gameData.data && gameData.data.videoFinal" :href="gameData.data.videoFinal" target="_blank">
							<v-icon left>mdi-play</v-icon> Veja o Vídeo de Finalização
						</v-btn>
						<a v-if="gameData && gameData.data && gameData.data.videoFinal" class="d-none d-print-block" :href="gameData.data.videoFinal" target="_blank">Veja o vídeo de finalização</a>
					</p>
				</v-col>
				<v-col sm="0" md="2" class="d-print-none" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script>
import moment from 'moment'
import currency from 'currency.js'
import { Container, Draggable } from 'vue-smooth-dnd'
import imgLogo from '../../assets/img/etctao.png'
import cardLogo from '../../assets/img/card-bg.jpg'
import PolarAreaChart from '../../components/charts/PolarAreaChart.vue'
import BarChart from '../../components/charts/BarChart.vue'
import FacProducts from '../../components/items/Product.vue'

export default {
	name: 'ReportFAC',
	props: ['id', 'u'],
	components: {
		Container,
		Draggable,
		'fac-polar-area': PolarAreaChart,
		'fac-bar-chart': BarChart,
		'fac-products': FacProducts
	},
	data() {
		return {
			// DATA
			userData: [],
			gameData: [],
			reportData: [],
			productsData: [],

			// CLIENT INFO
			differentClient: false,
			clientData: [],

			// SALE OPTIONS
			saleOptions: null,
			saleOptions2: null,

			// CHARTS SETTINGS
			invalidReport: false,
			enableReport: false,
			showCharts: false,
			chartData: [],
			chartOptions: [],
			chartDataBar: [],
			chartDataBarOptions: [],
			chartDataGroups: [],
			chartDataGroupsOptions: [],

			// REPORT
			lastFinishedGame: [],
			processedDimensions: []
		}
	},
	mounted() {
		this.fetchData()
		this.$bus.$emit('DISABLE_ANIMS')
	},
	beforeDestroy() {
		this.$bus.$emit('ENABLE_ANIMS')
	},
	methods: {
		BRL(value, precision) {
			return currency(value, { precision: precision || 2, separator: ".", decimal: ",", symbol: "R$ " })
		},
		fetchData() {
			Promise.resolve().then(() => {
				return this.$store.dispatch('checkAuth').then(result => {
					if (result && result.content && result.content.id) {
						this.userData = result.content
					}
				})
			}).then(() => {
				if (this.u && this.userData && this.u !== this.userData.id) {
					this.differentClient = true
					return this.$store.dispatch('getUserSimpleData', this.u).then(result => {
						if (result && result.content && result.content.id) {
							this.clientData = result.content
						}
					})
				}
				else {
					return true
				}
			}).then(() => {
				return this.$store.dispatch('fetchProducts').then(result => {
					if (result && result.length > 0) {
						for (let product of result) {
							this.productsData.push({
								code: product.code,
								name: product.name,
								price: product.price ? this.BRL(product.price).format() : product.price,
								hasDiscount: product.hasDiscount,
								discount: product.discount
							})
						}
					}
				})
			}).then(() => {
				if (this.id && this.u && this.userData && this.userData.id) {
					let data = {
						id: this.id,
						user: this.u,
						by: this.userData.id
					}

					return this.$store.dispatch('fetchUserGameRecord', data).then(result => {
						if (result && result.id) {
							this.reportData = result

							return this.$store.dispatch('fetchGameFACReport', { game: this.id, id: this.u } ).then(result => {
								if (result && result.data) {
									this.gameData = {...result}
								}
							})
						}
					})
				}
				else {
					throw new Error('INVALID_REPORT')
				}
			}).then(() => {
				this.enableReport = true
				this.$bus.$emit('DISABLE_ANIMS')
				this.processReport()
			}).catch(() => {
				this.invalidReport = true
			})
		},
		formatDate(date) {
			return moment(date).format('DD/MM/YYYY HH:mm')
		},
		callPrint() {
			window.print()
		},
		enterSale(product) {
			if (product && product.code) {
				sessionStorage.setItem('fac-product', product.code)
				setTimeout(() => {
					this.$router.push('/comprar')
				}, 300)
			}
		},
		processReport() {
			if (this.enableReport && this.userData && this.gameData && this.reportData) {
				// SET COLORS
				const redColor = 'rgb(255, 99, 132)'
				const yellowColor = 'rgb(255, 205, 86)'
				const greenColor = 'rgb(75, 192, 104)'
				const blueColor = 'rgb(54, 162, 235)'
				const redColorA = 'rgba(255, 99, 132, 0.5)'
				const yellowColorA = 'rgba(255, 205, 86, 0.5)'
				const greenColorA = 'rgba(75, 192, 104, 0.5)'
				const blueColorA = 'rgba(54, 162, 235, 0.5)'

				// RESET
				this.processedDimensions = []

				// BUILD PROCESSED REPORT DATA
				let groupIndex = 1
				for (let group of this.gameData.groups) {
					if (this.reportData.accessType > 1) {
						this.processedDimensions.push({
							id: group.id,
							name: group.name,
							video: group.videoLink,
							dimensions: []
						})
					}
					else {
						if (groupIndex === 1) {
							this.processedDimensions.push({
								id: group.id,
								name: group.name,
								video: group.videoLink,
								dimensions: []
							})
						}
					}
					groupIndex++
				}

				// ADD DESCRIPTION ON GROUPS
				let dimensionIndex = 1
				for (let group of this.processedDimensions) {
					for (let dimension of this.reportData.pointsByDimension) {
						for (let dim of this.gameData.dimensions) {
							if (dimension.dimension === dim.id && dim.group === group.id) {
								if (this.reportData.accessType > 1) {
									group.dimensions.push({
										id: dim.id,
										name: dim.name,
										score: dimension.formattedPoints,
										description: dimension.formattedPoints <= 4 ? dim.reports.below : dimension.formattedPoints > 4 && dimension.formattedPoints <= 7 ? dim.reports.neutral : dimension.formattedPoints > 7 && dimension.formattedPoints <= 10 ? dim.reports.above : '',
										video: dim.videos ? dimension.formattedPoints <= 4 ? dim.videos.below : dimension.formattedPoints > 4 && dimension.formattedPoints <= 7 ? dim.videos.neutral : dimension.formattedPoints > 7 && dimension.formattedPoints <= 10 ? dim.videos.above : null : null,
										descriptive: {
											characteristic: dim.descriptive && dim.descriptive.characteristic ? dimension.formattedPoints <= 4 ? dim.descriptive.characteristic.below : dimension.formattedPoints > 4 && dimension.formattedPoints <= 7 ? dim.descriptive.characteristic.neutral : dimension.formattedPoints > 7 && dimension.formattedPoints <= 10 ? dim.descriptive.characteristic.above : null : null,
											description: dim.descriptive && dim.descriptive.description ? dimension.formattedPoints <= 4 ? dim.descriptive.description.below : dimension.formattedPoints > 4 && dimension.formattedPoints <= 7 ? dim.descriptive.description.neutral : dimension.formattedPoints > 7 && dimension.formattedPoints <= 10 ? dim.descriptive.description.above : null : null,
											improvement: dim.descriptive && dim.descriptive.improvements ? dimension.formattedPoints <= 4 ? dim.descriptive.improvements.below : dimension.formattedPoints > 4 && dimension.formattedPoints <= 7 ? dim.descriptive.improvements.neutral : dimension.formattedPoints > 7 && dimension.formattedPoints <= 10 ? dim.descriptive.improvements.above : null : null,
											positive: dim.descriptive && dim.descriptive.positives ? dimension.formattedPoints <= 4 ? dim.descriptive.positives.below : dimension.formattedPoints > 4 && dimension.formattedPoints <= 7 ? dim.descriptive.positives.neutral : dimension.formattedPoints > 7 && dimension.formattedPoints <= 10 ? dim.descriptive.positives.above : null : null
										}
									})
								}
								else {
									if (dimensionIndex === 1) {
										group.dimensions.push({
											id: dim.id,
											name: dim.name,
											score: dimension.formattedPoints,
											description: dimension.formattedPoints <= 4 ? dim.reports.below : dimension.formattedPoints > 4 && dimension.formattedPoints <= 7 ? dim.reports.neutral : dimension.formattedPoints > 7 && dimension.formattedPoints <= 10 ? dim.reports.above : '',
											video: dim.videos ? dimension.formattedPoints <= 4 ? dim.videos.below : dimension.formattedPoints > 4 && dimension.formattedPoints <= 7 ? dim.videos.neutral : dimension.formattedPoints > 7 && dimension.formattedPoints <= 10 ? dim.videos.above : null : null,
										})
									}
								}
							}
						}

						dimensionIndex++
					}
				}

				// BUILD DIMENSIONS CHART DATA
				let dimensionLabels = []
				let dimensionScores = []
				let dimensionColors = []
				if (this.reportData.pointsByDimension && this.reportData.pointsByDimension.length === 16) {
					for (let dimension of this.reportData.pointsByDimension) {
						for (let dim of this.gameData.dimensions) {
							if (dimension.dimension === dim.id) {
								dimensionLabels.push(dim.name)
								dimensionScores.push(dimension.formattedPoints)

								if (dimension.formattedPoints <= 4) {
									dimensionColors.push(redColor)
								}
								else if (dimension.formattedPoints > 4 && dimension.formattedPoints <= 7) {
									dimensionColors.push(yellowColor)
								}
								else if (dimension.formattedPoints > 7 && dimension.formattedPoints <= 10) {
									dimensionColors.push(greenColor)
								}
							}
						}
					}
				}
				// BUILD GROUPS CHART DATA
				let groupLabels = []
				let groupScores = []
				let groupColors = []
				if (this.reportData.pointsByGroup && this.reportData.pointsByGroup.length === 4) {
					for (let group of this.reportData.pointsByGroup) {
						for (let gp of this.gameData.groups) {
							if (group.group === gp.id) {
								let groupPercentage = Number(group.points/2).toFixed()
								groupLabels.push(`${gp.name}: ${groupPercentage}%`)
								groupScores.push(groupPercentage)
								// groupColors.push(blueColor)
							}
						}
					}

					this.reportData.pointsByGroup.forEach((group, index) => {
						if (index === 0) { groupColors.push(blueColor) }
						else if (index === 1) { groupColors.push(redColor) }
						else if (index === 2) { groupColors.push(yellowColor) }
						else if (index === 3) { groupColors.push(greenColor) }
					})
				}

				// DIMENSION CHART
				this.chartData = {
					labels: dimensionLabels,
					datasets: [
						{
							label: 'Grupos',
							data: [10, 10, 10, 10],
							backgroundColor: [blueColorA, redColorA, yellowColorA, greenColorA],
							labels: groupLabels,
							order: 1,
							datalabels: {
								display: false
							}
						},
						{
							label: 'Dimensões',
							data: dimensionScores,
							backgroundColor: dimensionColors,
							labels: dimensionLabels,
							order: 0,
							datalabels: {
								anchor: 'end',
								align: 'center',
								backgroundColor: (context) => {
									return context.dataset.backgroundColor
								},
								borderColor: 'white',
								borderRadius: 50,
								borderWidth: 2,
								color: 'white',
								font: {
									size: 10,
									weight: 'bold'
								}
							},
						}
					]
				}
				this.chartOptions = {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						},
						tooltip: {
							enabled: false
						}
					},
					scales: {
						r: {
							min: 0,
							max: 10,
							ticks: {
								display: true
							},
							grid: {
								display: true
							}
						}
					},
					layout: {
                        padding: 0
                    }
				}

				// BAR CHART
				this.chartDataBarOptions = {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						},
						tooltip: {
							enabled: false
						}
					},
					scales: {
						y: {
							min: 0,
							max: 10
						},
						x: {
							grid: {
								display: false
							}
						}
					},
					layout: {
                        padding: 20
                    }
				}
				this.chartDataBar = {
					labels: dimensionLabels,
					datasets: [{
						label: 'Valor',
						data: dimensionScores,
						backgroundColor: dimensionColors,
						datalabels: {
							anchor: 'center',
							backgroundColor: (context) => {
								return context.dataset.backgroundColor
							},
							borderColor: 'white',
							borderRadius: 50,
							borderWidth: 2,
							color: 'white',
							font: {
								size: 10,
								weight: 'bold'
							}
						},
					}]
				}

				// GROUPS CHART
				this.chartDataGroups = {
					labels: groupLabels,
					datasets: [{
						label: 'Valor',
						data: groupScores,
						backgroundColor: groupColors,
						datalabels: {
							anchor: 'end'
						}
					}]
				}
				this.chartDataGroupsOptions = {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						},
						tooltip: {
							enabled: false
						},
						datalabels: {
							anchor: 'end',
							backgroundColor: (context) => {
								return context.dataset.backgroundColor;
							},
							borderColor: 'white',
							borderRadius: 25,
							borderWidth: 2,
							color: 'white',
							font: {
								weight: 'bold'
							},
							padding: 6,
							align: 'center',
							formatter: (value, context) => {
								return context.chart.data.labels[context.dataIndex]
							}
						}
					},
					scales: {
						r: {
							ticks: {
								display: true
							},
							grid: {
								display: true
							}
						}
					}
				}

				// ENABLE CHARTS PAGE
				this.showCharts = true
			}
		}
	}
}
</script>

<style scoped>
/* PRINT OPTIONS */
@media print {
	.pagebreak { page-break-before: always; }
}

/* REPORT CARD IMAGE */
.report-card-img {
	position: absolute;
	z-index: 0;
	width: 200px;
	height: 270px;
	top: 0;
	left: 0;
	opacity: 0.5;
}

/* GRAPH LEGEND */
.chip-green {
	border-radius: 25px;
	background-color: #4bc068;
	-webkit-print-color-adjust: exact; 
}
.chip-yellow {
	border-radius: 25px;
	background-color: #ffcd56;
	-webkit-print-color-adjust: exact; 
}
.chip-red {
	border-radius: 25px;
	background-color: #ff6384;
	-webkit-print-color-adjust: exact; 
}
</style>