<template>
	<div>
		<canvas :id="chartname" width="400" height="400"></canvas>
	</div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
	name: 'fac-bar-chart',
	props: ['chartname', 'chartdata', 'options'],
	data() {
		return {
			drawnGraph: []
		}
	},
	mounted() {
		this.drawGraph()
	},
	beforeDestroy() {
		this.drawnGraph.destroy()
	},
	methods: {
		drawGraph() {
			setTimeout(() => {
				this.drawnGraph = new Chart(this.chartname, {
					type: 'bar',
					data: this.chartdata,
					options: this.options
				})
			}, 150)
		}
	}
}
</script>
